<template>
  <div class="dynamic-form-editor">
    <h4 class="font-weight-bold">
      {{ editorTitle }}
      <b-button class="ml-2" variant="secondary" @click="exportSubjects">
        匯出 JSON
      </b-button>
      <b-button class="ml-2" variant="secondary" @click="importSubjects">
        匯入 JSON
      </b-button>
    </h4>

    <section class="dynamic-form-editor__subjects">
      <VueDraggable
        handle=".subject-layout-drag-handler"
        :list="newSubjects"
        :disabled="isReadOnly"
      >
        <b-card v-for="(newSubject, index) in newSubjects" class="mt-3" :key="`i${newSubjects[index]._id}r${rerenderingCount}`">
          <SubjectLayout
            :ref="'subjectLayoutRef+'+newSubjects[index]._id"
            :index="index"
            :type="newSubject.type"
            :title.sync="newSubjects[index].title"
            :memo.sync="newSubjects[index].memo"
            :placeholder.sync="newSubjects[index].placeholder"
            :required.sync="newSubjects[index].required"
            :dashboardOnly.sync="newSubjects[index].dashboard_only"
            :mainDisplay.sync="newSubjects[index].main_display"
            :move-up-is-disabled="index == 0"
            :move-down-is-disabled="index == newSubjects.length - 1"
            :has-placeholder="['text', 'textarea', 'select', 'datetime', 'collection_carousel'].includes(newSubject.type)"
            :expanded.sync="expandedStateForSubjects[newSubjects[index]._id]"
            :form-type="formType"
            :listHidden.sync="newSubjects[index].list_hidden"
            :readonly="isReadOnly"
            :page.sync="newSubjects[index].page_id"
            :page-options="pages"
            :section.sync="newSubjects[index].section_id"
            :section-options="sections"
            :visible-conditions.sync="newSubjects[index].visible_when_any"
            :field-options-for-visible-conditions="inputSubjects"
            @top="moveToTop(index)"
            @up="moveUp(index)"
            @down="moveDown(index)"
            @bottom="moveToBottom(index)"
            @remove="removeNewSubject(index)"
          >
            <template #default>
              <!-- radio, checkbox, select start -->
              <b-form-group v-if="['radio', 'checkbox', 'select'].includes(newSubject.type)" label="選項 *" label-size="sm">
                <div class="mt-3">
                  <VueDraggable
                    handle=".option-drag-handler"
                    :list="newSubjects[index].options"
                    :disabled="isReadOnly"
                  >
                    <div v-for="(option, optionIndex) in newSubjects[index].options" class="mb-3 d-flex align-items-center" :key="index + '-opt-' + optionIndex">
                      <div class="flex-grow-1 flex-shrink-1 d-flex align-items-center">
                        <i class="fa fa-bars mr-3 option-drag-handler"></i>
                        <b-form-input
                          placeholder="請輸入選項"
                          v-model="newSubjects[index].options[optionIndex].text"
                          :disabled="isReadOnly"
                          @change="syncOptionValue(index, optionIndex)"
                          @compositionstart="compositionStart"
                          @compositionend="compositionEnd(index, optionIndex)"
                          @keydown.enter="event => handleKeyDown({event, subjectIndex: index, optionIndex, id: newSubject._id})"
                          @paste="e => handlePaste(e, index, optionIndex)"
                          :ref="`${newSubject._id}_${optionIndex}`">
                        </b-form-input>
                      </div>
                      <div class="flex-grow-0 flex-shrink-0" v-if="!isReadOnly">
                        <b-button class="ml-2 p-1 d-inline-flex align-items-center" variant="inverse-success" @click="createOption(optionIndex,newSubject._id)">
                          <span class="mdi mdi-18px mdi-playlist-plus"></span>
                          增加
                        </b-button>
                        <b-button class="ml-2 p-1 d-inline-flex align-items-center" variant="inverse-danger" @click="removeOption(index, optionIndex)">
                          <span class="mdi mdi-18px mdi-playlist-remove"></span>
                          移除
                        </b-button>
                      </div>
                    </div>
                  </VueDraggable>
                </div>
              </b-form-group>
              <!-- radio, checkbox, select end -->

              <!-- datetime start -->
              <b-form-group v-if="newSubject.type === 'datetime'" label="格式 *" label-size="sm" :disabled="isReadOnly">
                <b-form-radio-group
                  class="input-group"
                  v-model="newSubjects[index].format"
                  :options="[{ text: '日期 + 時間', value: 'datetime' }, { text: '日期', value: 'date' }, { text: '時間', value: 'time' }]"
                ></b-form-radio-group>
              </b-form-group>
              <!-- datetime end -->

              <!-- collection select start -->
              <b-form-group v-if="newSubject.type === 'collection_select'" label="資料集關聯 *" label-size="sm" :disabled="isReadOnly">
                <b-form-select
                  v-model="newSubjects[index].collection_id"
                  :options="collectionOptions"
                  v-on:change="handleCollectionSelectChange($event, newSubjects[index]._id)"
                ></b-form-select>
              </b-form-group>
              <!-- collection select end -->

              <!-- collection carousel start -->
              <template v-if="newSubject.type === 'collection_carousel'">
                <b-form-group label="資料集關聯 *" label-size="sm" :disabled="isReadOnly">
                  <b-form-select
                    v-model="newSubjects[index].collection_id"
                    :options="collectionOptions"
                    @input="(collectionId) => handleCollectionCarouselChange(collectionId, newSubjects[index])"
                  >
                    <template #first>
                      <b-form-select-option :value="null">請選擇資料集</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-overlay :show="isFetchCollectionFields[newSubjects[index].collection_id] === true">
                  <b-form-group label="標題欄位" label-size="sm" :disabled="isReadOnly">
                    <b-form-select
                      v-model="newSubjects[index].collection_title"
                      :options="deepGet(collectionFieldOptions, newSubjects[index].collection_id, [])"
                      @input="(mappingFieldId) => handleCollectionCarouselFieldMappingChange(mappingFieldId, newSubjects[index])"
                    >
                      <template #first>
                        <b-form-select-option :value="null">請設定欄位對應</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="圖片欄位" label-size="sm" :disabled="isReadOnly">
                    <b-form-select
                      v-model="newSubjects[index].collection_image"
                      :options="deepGet(collectionFieldOptions, newSubjects[index].collection_id, [])"
                      @input="(mappingFieldId) => handleCollectionCarouselFieldMappingChange(mappingFieldId, newSubjects[index])"
                    >
                      <template #first>
                        <b-form-select-option :value="null">請設定欄位對應</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="說明欄位" label-size="sm" :disabled="isReadOnly">
                    <b-form-select
                      v-model="newSubjects[index].collection_description"
                      :options="deepGet(collectionFieldOptions, newSubjects[index].collection_id, [])"
                      @input="(mappingFieldId) => handleCollectionCarouselFieldMappingChange(mappingFieldId, newSubjects[index])"
                    >
                      <template #first>
                        <b-form-select-option :value="null">請設定欄位對應</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-overlay>
              </template>
              <!-- collection carousel end -->
            </template>


            <template #preview>
              <b-form-group
                class="mb-0"
                :class="`form-group-${newSubject.type}`"
                :label="newSubjects[index].title"
                :description="newSubjects[index].memo"
              >
                <template v-if="newSubject.type === 'text'">
                  <b-form-input
                    :placeholder="newSubjects[index].placeholder">
                  </b-form-input>
                </template>
                <template v-if="newSubject.type === 'textarea'">
                  <b-form-textarea
                    :placeholder="newSubjects[index].placeholder"
                    :rows="newSubjects[index].rows"
                  >
                  </b-form-textarea>
                </template>

                <template v-if="newSubject.type === 'select'">
                  <b-form-select
                    :value="null"
                    :options="previewOptions(index)"
                  >
                  </b-form-select>
                </template>

                <template v-if="newSubject.type === 'radio'">
                  <b-form-radio-group class="input-group" :options="previewOptions(index)"></b-form-radio-group>
                </template>

                <template v-if="newSubject.type === 'checkbox'">
                  <b-form-checkbox-group
                    class="input-group"
                    :options="previewOptions(index)"
                    :stacked="newSubjects[index].is_vertical"
                  ></b-form-checkbox-group>
                </template>

                <template v-if="newSubject.type === 'datetime'">
                  <template v-if="newSubjects[index].format === 'datetime'">
                    <AppDateTimePicker :placeholder-for-date="newSubjects[index].placeholder" :placeholder-for-time="newSubjects[index].placeholder"></AppDateTimePicker>
                  </template>
                  <template v-if="newSubjects[index].format === 'date'">
                    <AppDatePicker :placeholder="newSubjects[index].placeholder"></AppDatePicker>
                  </template>
                  <template v-if="newSubjects[index].format === 'time'">
                    <AppTimePicker :placeholder="newSubjects[index].placeholder"></AppTimePicker>
                  </template>
                </template>

                <template v-if="newSubject.type === 'collection_select'">
                  <b-form-select
                    :value="null"
                    :options="previewCollectionItemOptions[newSubjects[index]._id]"
                  ></b-form-select>
                </template>

                <template v-if="newSubject.type === 'collection_carousel'">
                  <CarouselSelection
                    :ref="`carousel-preview-${newSubjects[index]._id}`"
                    :value="null"
                    :placeholder="newSubjects[index].placeholder"
                    :candidate-fetcher="() => fetchCollectionItemForCarousel(newSubjects[index])"
                  ></CarouselSelection>
                </template>
              </b-form-group>
            </template>
          </SubjectLayout>
        </b-card>
      </VueDraggable>
    </section>

    <div v-if="!isReadOnly" class="subject-type-menu mt-3">
      <span class="subject-type-menu__icon mdi mdi-24px mdi-plus"></span>
      <div class="subject-type-menu__button-wrapper">
        <b-button class="subject-type-menu__create-button" variant="inverse-primary" @click="createNewSubject('text')">
          <i class="mdi mdi-18px mdi-format-title"></i>
          單行文字
        </b-button>
        <b-button class="subject-type-menu__create-button" variant="inverse-primary" @click="createNewSubject('textarea')">
          <i class="mdi mdi-18px mdi-format-size"></i>
          多行文字
        </b-button>
        <b-button class="subject-type-menu__create-button" variant="inverse-primary" @click="createNewSubject('radio')">
          <i class="mdi mdi-18px mdi-radiobox-marked"></i>
          單選題
        </b-button>
        <b-button class="subject-type-menu__create-button" variant="inverse-primary" @click="createNewSubject('checkbox')">
          <i class="mdi mdi-18px mdi-checkbox-marked-outline"></i>
          多選題
        </b-button>
        <b-button class="subject-type-menu__create-button" variant="inverse-primary" @click="createNewSubject('select')">
          <i class="mdi mdi-18px mdi-chevron-down"></i>
          下拉選單
        </b-button>
        <b-button class="subject-type-menu__create-button" variant="inverse-primary" @click="createNewSubject('datetime')" v-if="formType === 'booking'">
          <i class="mdi mdi-18px mdi-clock"></i>
          日期與時間
        </b-button>
        <b-button class="subject-type-menu__create-button" variant="inverse-primary" @click="createNewSubject('collection_select')" v-if="formType === 'booking'">
          <i class="mdi mdi-18px mdi-file-tree"></i>
          資料集關聯
        </b-button>
        <b-button class="subject-type-menu__create-button" variant="inverse-primary" @click="createNewSubject('collection_carousel')" v-if="formType === 'booking'">
          <i class="mdi mdi-18px mdi-file-tree"></i>
          資料集開窗
        </b-button>
      </div>
    </div>

    <div v-if="!isReadOnly" class="mt-3 d-flex justify-content-center">
      <b-button variant="outline-danger" class="mr-3" @click="$emit('cancel')">
        返回
      </b-button>
      <b-button variant="success" @click="outputSubjects">
        儲存
      </b-button>
    </div>

    <!-- 顯示匯出的 JSON Modal Start -->
    <b-modal
      id="export-subjects-json"
      title="題目 JSON 輸出"
      cancel-title="取消"
    >
      <b-form-textarea v-model="exportSubjectsJson"></b-form-textarea>
    </b-modal>
    <!-- 顯示匯出的 JSON Modal End -->

    <!-- 匯入 JSON 的地方 Modal Start -->
    <b-modal
      id="import-subjects-json"
      title="匯入題目 JSON"
      cancel-title="取消"
      ok-title="匯入"
      @ok="saveImportSubjects"
    >
      <b-form-textarea v-model="importSubjectsJson" rows="10"></b-form-textarea>
      <p>一但匯入，將會將目前題目全數以匯入的 JSON 覆蓋，請確認</p>
      <p>本編輯器可支援 member_register.member_fields, survey_subjects</p>
    </b-modal>
    <!-- 匯入 JSON 的地方 Modal End -->
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import VueDraggable from "vuedraggable";
import SubjectLayout from './SubjectLayout.vue';
import collectionApi from "@/apis/collection";
import AppDatePicker from '@/components/AppDatePicker.vue';
import AppDateTimePicker from '@/components/AppDateTimePicker.vue';
import AppTimePicker from '@/components/AppTimePicker.vue';
import CarouselSelection from "@/components/DynamicForm/CarouselSelection";
import Collection from "@/models/Collection.model.js";
import deepGet from "lodash/get";

export default {
  components: {
    VueDraggable,
    SubjectLayout,
    AppDatePicker,
    AppDateTimePicker,
    AppTimePicker,
    CarouselSelection,
  },
  props: {
    editorTitle: {
      type: String,
      default: '動態表單編輯器',
    },
    inputSubjects: {
      type: Array,
      default: () => { return [] },
    },
    formType: {
      type: String,
      default: 'survey',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Array,
      default: () => ([]),
    },
    sections: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    isReadOnly() {
      return this.readonly;
    },
  },
  watch: {
    inputSubjects: {
      handler: function (val) {
        for (let i = 0; i < val.length; i ++) {
          let { config, ...subject } = val[i];

          // 把 subject.config 轉換成 subject 的屬性，這裡是專門用來處理 survey_subjects 的 DB 儲存格式用的
          if (config) {
            val[i] = { ...config, ...subject };
          }
        }

        function sortByKeyAsc(array, key) {
          return array.sort(function(a, b) {
              var x = a[key]; var y = b[key];
              return ((x < y) ? -1 : ((x > y) ? 1 : 0));
          });
        }

        this.newSubjects = sortByKeyAsc([...val], 'order'); // NOTE: using ... operator to avoid call-by-reference

        this.fetchFieldOptionsForCollectionCarousels();
      },
      immediate: true,
    },
  },
  data() {
    return {
      newSubjects: [],
      expandedStateForSubjects: {},
      exportSubjectsJson: null,
      importSubjectsJson: null,
      rerenderingCount: 0,
      collectionOptions: [],
      collectionFieldOptions: {},
      isFetchCollectionFields: {},
      previewCollectionItemOptions: {},
      isTyping: false,
    }
  },
  mounted() {
    this.fetchCollections();
    // window.addEventListener('scroll', function() {
    //   var container = document.querySelector('.dynamic-form-editor');
    //   var bottomButtons = document.querySelector('.bottom-buttons');
    //   var containerBottom = container.getBoundingClientRect().bottom - 87;

    //   if (containerBottom <= window.innerHeight) {
    //     bottomButtons.classList.add('non-fixed');
    //   } else {
    //     bottomButtons.classList.remove('non-fixed');
    //   }
    // });
  },
  methods: {
    deepGet,
    createNewSubject(newSubjectType) {
      let subject = {};

      switch (newSubjectType) {
        case 'textarea':
          subject.rows = 4;
          break;
        case "select":
          subject.enable_option_placeholder = false;
          subject.option_placeholder = '請選擇下方選項';
          subject.options = [
            {
              text: "",
              value: "",
            }
          ];
          break;
        case "radio":
        case "checkbox":
          subject.options = [
            {
              text: "",
              value: "",
            }
          ];
          break;
        case "collection_select":
          subject.collection_id = null;
          break;
        case "collection_carousel":
          subject.collection_id = null;
          subject.collection_title = null;
          subject.collection_image = null;
          subject.collection_description = null;
          break;
      }

      subject._id = uuidv4();
      subject.type = newSubjectType;

      this.newSubjects.push(subject);
      this.initExpandedStateForNewSubject(subject)
      this.focusSubjectTitleInput(subject._id);
    },
    focusSubjectTitleInput(subjectId) {
      this.$nextTick(() => {
        this.$refs[`subjectLayoutRef+${subjectId}`][0].$refs.titleInputRef.focus();
      });
    },
    initExpandedStateForNewSubject(subject) {
      this.expandedStateForSubjects[subject._id] = true;
    },
    removeNewSubject(index) {
      this.newSubjects.splice(index, 1);

      this.rerenderingCount++;
    },
    createOption(optionIndex, id) {
      const clickedSubjectValue = this.newSubjects.find((subject) => subject._id === id).options[optionIndex].value;
      const nextOptionIndex = optionIndex + 1

      this.newSubjects.find((subject) => subject._id === id).options.splice(nextOptionIndex, 0, {
        text: clickedSubjectValue,
        value: clickedSubjectValue,
      });

      this.rerenderingCount++;

      this.$nextTick(() => {
        this.$refs[`${id}_${nextOptionIndex}`][0].select();
      });
    },
    removeOption(subjectIndex, optionIndex) {
      this.newSubjects[subjectIndex].options.splice(optionIndex, 1);

      this.rerenderingCount++;
    },
    previewOptions(subjectIndex) {
      if (this.newSubjects.length === 0 || this.newSubjects[subjectIndex]?.options === undefined) return [];

      let options = this.newSubjects[subjectIndex].options.slice();

      if (this.newSubjects[subjectIndex].placeholder) {
        options.unshift({
          text: this.newSubjects[subjectIndex].placeholder,
          value: null,
          disabled: true,
        });
      }

      options = options.filter((option) => {
        return option.text !== "" && option.text !== null && option.text !== undefined;
      });

      return options;
    },
    syncOptionValue(subjectIndex, optionIndex) {
      this.newSubjects[subjectIndex].options[optionIndex].value = this.newSubjects[subjectIndex].options[optionIndex].text;
    },
    handleKeyDown({subjectIndex, optionIndex, id}) {
      if(this.isTyping) return;
      this.syncOptionValue(subjectIndex, optionIndex)
      this.createOption(optionIndex, id)
      this.$nextTick(() => {
        this.$refs[`${id}_${optionIndex + 1}`][0].select();
      });
    },
    compositionStart() {
      this.isTyping = true;
    },
    compositionEnd(subjectIndex, optionIndex){
      this.isTyping = false
      this.syncOptionValue(subjectIndex, optionIndex)
    },
    handlePaste(event, subjectIndex, optionIndex){
      const pasteData = event.clipboardData;
      const pastedText = pasteData.getData('text');

      if (pastedText.includes('\n')) {
        const newOptions = pastedText.split('\n').map((text) => {
          return {
            text: text.trim(),
            value: text.trim(),
          };
        });
        this.newSubjects[subjectIndex].options.splice(optionIndex + 1, 0, ...newOptions);
        this.newSubjects[subjectIndex].options.splice(optionIndex, 1);

        this.rerenderingCount++;

        // 由於此元件採用 rerendering 做 forceUpdate，使用 nextTick 無法完全確保元素已被渲染
        setTimeout(() => {
          this.$refs[`${this.newSubjects[subjectIndex]._id}_${optionIndex + newOptions.length - 1}`][0].select();
        }, 0);

      } else {
        this.newSubjects[subjectIndex].options[optionIndex].value = pastedText;
      }
    },
    outputSubjects() {
      this.$emit('output', this.newSubjects.map((subject, index) => {
        const { id, order, type, ...config } = subject // eslint-disable-line no-unused-vars
        return {
          id,
          type,
          config,
          order: index + 1
        }
      }));
    },
    exportSubjects() {
      this.exportSubjectsJson = JSON.stringify(this.newSubjects);
      this.$bvModal.show('export-subjects-json');
    },
    importSubjects() {
      this.$bvModal.show('import-subjects-json');
    },
    saveImportSubjects() {
      let subjects = JSON.parse(this.importSubjectsJson);
      this.newSubjects = subjects;
    },
    moveToTop(index) {
      if (index === 0) return;

      const subjectToMove = this.newSubjects[index];
      for (let i = index; i > 0; i--) {
        this.newSubjects[i] = this.newSubjects[i - 1];
      }
      this.newSubjects[0] = subjectToMove;

      this.rerenderingCount++;
    },
    moveUp(index) {
      if (index === 0) return;

      let temp = this.newSubjects[index];
      this.newSubjects[index] = this.newSubjects[index - 1];
      this.newSubjects[index - 1] = temp;

      this.rerenderingCount++;
    },
    moveDown(index) {
      if (index === this.newSubjects.length - 1) return;

      let temp = this.newSubjects[index];
      this.newSubjects[index] = this.newSubjects[index + 1];
      this.newSubjects[index + 1] = temp;

      this.rerenderingCount++;
    },
    moveToBottom(index) {
      if (index === this.newSubjects.length - 1) return;

      const subjectToMove = this.newSubjects[index];
      for (let i = index; i < this.newSubjects.length - 1; i++) {
        this.newSubjects[i] = this.newSubjects[i + 1];
      }
      this.newSubjects[this.newSubjects.length - 1] = subjectToMove;

      this.rerenderingCount++;
    },
    async fetchCollections() {
      try {
        let response = await collectionApi.getCollections({
          with_items: 1,
          is_enabled: 1,
        });
        this.collectionOptions = response.data.data.map((collection) => {
          return {
            value: collection.id,
            text: collection.name + ' 共 ' + collection.items_count + ' 筆資料',
            items: collection.items,
            config: collection.config,
          };
        });

        // 如果 subjects 中，有任何 collection_select，則要先提前將 previewCollectionItemOptions 設定好
        for (let i = 0; i < this.newSubjects.length; i++) {
          if (this.newSubjects[i].type === 'collection_select') {
            this.handleCollectionSelectChange(this.newSubjects[i].collection_id, this.newSubjects[i]._id);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCollectionItemForCarousel(subject) { // NOTE: 若 DynamicForm 與 DynamicFormDashboard 需要合併，則將此段邏輯改以 vue provide/inject 方式實作
      if ([null, undefined].includes(subject.collection_id)) {
        return []
      }

      try {
        const collection = deepGet(await collectionApi.getCollection(subject.collection_id), 'data.data', null)
        const collectionModel = new Collection(collection)

        const getValueFromCollectionItemByFieldId = (collectionItem, fieldId) => {
          if (fieldId === 'default') {
            return collectionModel.textForOption(collectionItem.id)
          }
          return deepGet(collectionItem.data.find(field => field.field_id === fieldId), 'value', null)
        }

        return collection.items.map((item) => ({
          id: item.id,
          title: getValueFromCollectionItemByFieldId(item, subject[`collection_title`]),
          image: getValueFromCollectionItemByFieldId(item, subject[`collection_image`]),
          description: getValueFromCollectionItemByFieldId(item, subject[`collection_description`]),
          value: {
            type: 'collection_carousel',
            collection_item_id: item.id,
            collection_id: collection.id,
            text: collectionModel.textForOption(item.id),
          },
          placeholderWhenSelected: collectionModel.textForOption(item.id),
        }))
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", `讀取 ${subject.title} 選項時發生錯誤，請重新整理後再試一次`, "error")
        return []
      }
    },
    async handleCollectionSelectChange(value, subjectId) {
      const collection = this.collectionOptions.find((collection) => collection.value === value);

      if (! collection) {
        return;
      }

      const collectionModel = new Collection(collection)

      this.$set(
        this.previewCollectionItemOptions,
        subjectId,
        collection.items.map((item) => ({
          value: item.id,
          text: collectionModel.textForOption(item.id),
        }))
      )
    },
    async fetchFieldOptionsForCollectionCarousels() {
      return await Promise.all(
        this.inputSubjects
          .filter(subject => subject.type === 'collection_carousel')
          .map(subject => this.fetchFieldOptionsByCollectionId(deepGet(subject, 'collection_id')))
      )
    },
    async fetchFieldOptionsByCollectionId(collectionId) {
      if ([null, undefined].includes(collectionId)) {
        return
      }

      this.isFetchCollectionFields[collectionId] = true

      const res = await collectionApi.getCollection(collectionId)
      this.$set(
        this.collectionFieldOptions,
        collectionId,
        [
          { text: '預設顯示文字', value: 'default' },
          ...deepGet(res, 'data.data.config.fields', [])
            .map(field => ({
              text: deepGet(field, 'config.title'),
              value: deepGet(field, 'config._id'),
            })),
        ]
      )

      this.isFetchCollectionFields[collectionId] = false
    },
    async handleCollectionCarouselChange(collectionId, subject) {
      subject.collection_title = null
      subject.collection_image = null
      subject.collection_description = null
      await this.fetchFieldOptionsByCollectionId(collectionId)
      await this.updateCollectionCarouselPreview(subject._id)
    },
    async handleCollectionCarouselFieldMappingChange(mappingFieldId, subject) {
      await this.updateCollectionCarouselPreview(subject._id)
    },
    async updateCollectionCarouselPreview(subjectId) {
      await this.$refs[`carousel-preview-${subjectId}`][0].fetchCandidates()
    },
  }
}
</script>

<style lang="scss" scoped>
.bottom-buttons {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #ffffff;
  z-index: 9;
  margin-left: -10px;
}

.non-fixed {
  position: static;
  margin-left: 0;
}



.form-group ::v-deep .form-row {
  .col {
    display: flex;
    align-items: center;
  }
}

.form-group-radio,
.form-group-checkbox {
  ::v-deep {
    .col {
      flex-direction: column;
      align-items: start;
    }

    .input-group {
      flex-direction: column;
      align-items: start;
    }
  }
}

.subject-type-menu {
  display: inline-flex;
  align-items: center;
  border: 1px dashed #aaa;
  border-radius: 12px;
  padding: 4px 8px;

  &__icon {
    flex: 0 0 auto;
    margin: 0 8px;
  }

  &__button-wrapper {
    flex: 1 1 auto;
    flex-wrap: wrap;

    > * {
      margin: 4px 8px;
    }
  }

  &__create-button {
    display: inline-flex;
    align-items: center;
    text-wrap: nowrap;

    i.mdi {
      margin-right: 4px;
    }
  }
}
</style>
